<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <p>{{ $t("recette.mois") }}</p>
        <a-select
          id="selectMonth"
          show-search
          :placeholder="$t('recette.tousMois')"
          option-filter-prop="children"
          style="width: 200px; margin-bottom: 20px"
          :filter-option="filterOption"
          @change="handleChangeSelectMois"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option value="01"> {{ moment.months(0) }} </a-select-option>
          <a-select-option value="02"> {{ moment.months(1) }} </a-select-option>
          <a-select-option value="03"> {{ moment.months(2) }} </a-select-option>
          <a-select-option value="04"> {{ moment.months(3) }} </a-select-option>
          <a-select-option value="05"> {{ moment.months(4) }} </a-select-option>
          <a-select-option value="06"> {{ moment.months(5) }} </a-select-option>
          <a-select-option value="07"> {{ moment.months(6) }} </a-select-option>
          <a-select-option value="08"> {{ moment.months(7) }} </a-select-option>
          <a-select-option value="09"> {{ moment.months(8) }} </a-select-option>
          <a-select-option value="10">
            {{ moment.months(9) }}
          </a-select-option>
          <a-select-option value="11">
            {{ moment.months(10) }}
          </a-select-option>
          <a-select-option value="12">
            {{ moment.months(11) }}
          </a-select-option>
        </a-select>

        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; float: right"
          :data="csvToExport"
          :name="'Etat impayés_scolarité.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="loadingData"
        >
          <template slot="expandedRowRender" slot-scope="record">
            <div style="margin: 0">
              <table class="table table-striped">
                <thead>
                  <th>{{ $t("recette.mois") }}</th>
                  <th>{{ $t("recette.montant") }}</th>
                  <th>{{ $t("topBar.status") }}</th>
                </thead>
                <tbody>
                  <tr v-if="record.classroom.inscription && mF">
                    <td>{{ $t("recette.inscription") }}</td>
                    <td>
                      <del v-if="record.repReduction.inscription > 0">
                        {{ record.classroom.inscription }}
                      </del>
                      <div v-else>
                        {{
                          getMonthPrix(
                            record,
                            record.classroom.inscription,
                            "inscription"
                          )
                        }}
                      </div>
                      <sup
                        v-if="record.repReduction.inscription > 0"
                        class="text-success"
                        >-{{ record.repReduction.inscription }}%</sup
                      >
                      <div v-if="record.repReduction.inscription > 0">
                        ={{
                          getMonthPrix(
                            record,
                            record.classroom.inscription,
                            "inscription"
                          )
                        }}
                      </div>
                    </td>
                    <td>
                      <a-tag :color="!record.inscription ? 'green' : 'red'">
                        <b v-if="!record.inscription">{{
                          $t("profil.payes")
                        }}</b>
                        <b v-else> {{ $t("profil.nonPayes") }}</b>
                      </a-tag>
                    </td>
                  </tr>
                  <tr v-for="(month, key) in record.months" :key="key">
                    <td>{{ getMonthName(key) }} ({{ key }})</td>
                    <td>
                      <div>
                        <del v-if="record.repReduction[key] > 0">
                          {{ record.classroom.monthly }}
                        </del>
                        <div v-else>
                          {{
                            getMonthPrix(record, record.classroom.monthly, key)
                          }}
                        </div>
                        <sup
                          v-if="record.repReduction[key] > 0"
                          class="text-success"
                          >-{{ record.repReduction[key] }}%</sup
                        >
                        <div v-if="record.repReduction[key] > 0">
                          ={{
                            getMonthPrix(record, record.classroom.monthly, key)
                          }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <a-tag :color="record.months[key] ? 'green' : 'red'">
                        <b v-if="record.months[key]">{{
                          $t("profil.payes")
                        }}</b>
                        <b v-else> {{ $t("profil.nonPayes") }}</b>
                      </a-tag>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.father.firstName }}
            {{ record.parent.father.lastName }}<br />
            {{ record.parent.mother.firstName }}
            {{ record.parent.mother.lastName }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.father.phone }}<br />
            {{ record.parent.mother.phone }}
          </template>
          <template slot="student" slot-scope="text, record">
            {{ record.student.firstName }} {{ record.student.lastName }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import moment from "moment";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months) {
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    }
    return "payed";
  }
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};

export default {
  components: {
    JsonCsv,
  },
  async created() {
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );

    apiClient
      .post("/payment/filter", {
        query: {
          status: { $ne: "inactive" },
          typeAS: "S",
        },
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classroom",
              foreignField: "_id",
              as: "classroom",
            },
          },
          {
            $project: {
              _id: 1,
              months: 1,
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              inscription: 1,
              defaultReduction: 1,
              repReduction: 1,
              typeAS: 1,
              classroom: {
                _id: 1,
                name: 1,
                inscription: 1,
                monthly: 1,
              },
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => {
          if (elem.repReduction)
            for (const key in elem.months)
              elem.repReduction[key] = elem.repReduction[key] || 0;
          return {
            _id: elem._id,
            name: elem.classroom[0].name,
            classroom: elem.classroom[0],
            inscription: elem.inscription,
            defaultReduction: elem.defaultReduction,
            repReduction: elem.repReduction,
            months: elem.months,
            student: elem.student[0],
            parent: elem.parent[0],
            once: false,
            type: "scolarity",
          };
        });
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          if (status !== "payed")
            this.data.push({
              ...elem,
              status: status,
            });
          this.rowData.push({
            ...elem,
            status: status,
          });
        });
        this.loadingData = false;
      });

    this.cacheData = this.data.map((item) => ({ ...item }));
    this.filtredTable = this.data;
  },
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      mF: true,
      moment,
      rowData: [],
      receiptTypes: [],
      students: [],
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      value: 1,
      loadingData: true,
      checkAll: false,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "parent",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            let clientName =
              record.parent.father.firstName +
              " " +
              record.parent.father.lastName +
              " " +
              record.parent.mother.firstName +
              " " +
              record.parent.mother.lastName;
            return clientName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },

        {
          title: this.$t("recette.telephone"),
          dataIndex: "parent",
          key: "phone",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "phone",
          },
          onFilter: (value, record) =>
            (record.parent.father.phone + " " + record.parent.mother.phone)
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "student",
          key: "student",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "student",
          },
          onFilter: (value, record) => {
            let studentName = record.student
              ? record.student.firstName + " " + record.student.lastName
              : "";
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("paiement.classe"),
          dataIndex: "name",
          key: "name",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    getMonthName,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    generatePdf() {
      this.$gtag.event("Imp etatImpayesScolarite", {
        event_category: "Impression PDF",
        event_label: "declarations:etatImpayesScolarite",
        value: 1,
      });

      let selectMonth = document.getElementById("selectMonth").innerText;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = "Etat des impayés - Scolarité";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(70, 60, titre);
      doc.text(89, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var somme = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        let inscriptionFees = 0;
        if (this.filtredTable[i].inscription === false) {
          inscriptionFees = this.getMonthPrix(
            this.filtredTable[i],
            this.filtredTable[i].classroom.inscription,
            "inscription"
          );
        }
        let currentIndex = 0;
        let monthsUnpaied = 0;
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            if (this.filtredTable[i].months[key] === false) monthsUnpaied++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          if (
            this.filtredTable[i].inscription === false &&
            inscriptionFees !== 0 &&
            selectMonth === "Tous les mois"
          )
            monthsUnpaied++;
          if (monthsUnpaied != 0) {
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content: this.filtredTable[i].parent.father.phone,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName,
              styles: { valign: "middle" },
            });
          }
        }
        if (
          this.filtredTable[i].inscription === false &&
          inscriptionFees !== 0 &&
          selectMonth === "Tous les mois"
        ) {
          if (!currentIndex) {
            arr.push(
              this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName
            );
            arr.push(this.filtredTable[i].parent.father.phone);
            arr.push(
              this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName
            );
          }

          if (this.filtredTable[i].classroom.inscription) {
            const ins = this.getMonthPrix(
              this.filtredTable[i],
              this.filtredTable[i].classroom.inscription,
              "inscription"
            );
            arr.push("Inscription " + this.filtredTable[i].name);
            arr.push(ins);
            somme += parseFloat(ins);
            array.push(arr);
            arr = [];
          }
        }
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            const monthly = this.getMonthPrix(
              this.filtredTable[i],
              this.filtredTable[i].classroom.monthly,
              key
            );
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            if (this.filtredTable[i].months[key] === false) {
              arr.push(monthName + " - " + this.filtredTable[i].name);
              arr.push(monthly);
              somme += parseFloat(monthly);
              //colspan
              array.push(arr);
              arr = [];
            }
          }
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Nom client", "Téléphone", "Enfant", "Frais", "Montant"]],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_des_impayés_activité.pdf");
    },
    showThisRow(record) {
      if (!record.inscription) return true;
      else {
        for (const key in record.months) {
          if (!record.months[key]) return true;
        }
        return false;
      }
    },
    handleChangeSelectMois(value) {
      const rowData = [];
      this.rowData.forEach((elem) => rowData.push({ ...elem }));
      if (value == "all") {
        this.mF = true;
        this.data = rowData;
      } else {
        this.mF = false;
        let newData = rowData.filter((elem) => !elem.once);
        this.data = newData.map((elem) => {
          const month = Object();
          Object.keys(elem.months).forEach((key) => {
            if (parseInt(key.split("-")[1]) == parseInt(value))
              month[key] = elem.months[key];
          });
          return {
            ...elem,
            months: month,
            status: getStatus({
              inscription: elem.inscription,
              months: month,
            }),
          };
        });
      }
      this.filtredTable = this.data.filter(
        (elem) => Object.keys(elem.months).length > 0 && this.showThisRow(elem)
      );
    },
    csvToExport() {
      const data = [...this.filtredTable];

      return data.map((item) => {
        const inscriptionFee = this.getMonthPrix(
          item,
          item.classroom.monthly,
          "inscription"
        );
        var totale = 0;
        let nonPaye = 0;
        let paye = 0;
        if (item.inscription) paye += inscriptionFee;
        else nonPaye += inscriptionFee;
        _.forEach(item.months, (value, key) => {
          const monthly = this.getMonthPrix(item, item.classroom.monthly, key);
          totale += monthly;
          if (value) {
            paye += monthly;
          } else {
            nonPaye += monthly;
          }
        });
        return {
          nomClient: `${item.parent.father.firstName} ${item.parent.father.lastName} ${item.parent.mother.firstName} ${item.parent.mother.lastName}`,
          telephone: `Pére : ${item.parent.father.phone} mére: ${item.parent.mother.phone}`,
          enfant: `${item.student.firstName} ${item.student.lastName}`,
          classe: `${item.classroom.name}`,
          montantTotale: totale,
          montantPayé: paye,
          montantNonPaye: nonPaye,
        };
      });
    },
    getMonthPrix(payment, originalPrice, month) {
      if (!originalPrice) return 0;
      originalPrice = parseFloat(originalPrice);
      if (payment.repReduction) {
        const reductionPercentage = parseFloat(payment.repReduction[month]);
        if (
          isNaN(reductionPercentage) ||
          reductionPercentage < 0 ||
          reductionPercentage > 100
        )
          return parseFloat(originalPrice);

        // Calculate the new price
        const reductionAmount = (originalPrice * reductionPercentage) / 100;
        const newPrice = originalPrice - reductionAmount;
        return parseFloat(newPrice);
      }
      return parseFloat(originalPrice);
    },
  },
};
</script>
